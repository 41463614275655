<template>
  <div
    class="keyboard-wrapper"
    v-click-outside="onClickOutside"
    :class="`${componentName ? componentName : ''}`"
  >
    <div class="hide-button" @click="$emit('close', true)">
      <svg
        width="384"
        height="220"
        viewBox="0 0 384 220"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.3333 26.3333L192 193L358.667 26.3333"
          stroke="black"
          stroke-width="50"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <input :value="input" class="input" :placeholder="placeholder" />
    <div :class="keyboardClass"></div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
import Keyboard from 'simple-keyboard';
import 'simple-keyboard/build/css/index.css';
import layout from 'simple-keyboard-layouts/build/layouts/german';

export default {
  name: 'SimpleKeyboard',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    keyboardClass: {
      default: 'simple-keyboard',
      type: String,
    },
    placeholder: {
      default: '',
      type: String,
    },
    input: {
      type: String,
    },
    componentName: {
      type: String,
      required: false,
    },
  },
  emits: ['onChange', 'onKeyPress', 'close', 'reset'],
  data: () => ({
    keyboard: null,
    isOpen: false,
  }),
  mounted() {
    const newLayout = JSON.parse(JSON.stringify(layout));

    if (this.componentName === 'DonatorsList') {
      newLayout.layout.default[4] = '.com @ {space} {reset}';
    }

    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      buttons:
        'A B C D E F G H I J K L M N O P Q R S T U V W X Y Z {enter} {bksp} {qwe}',
      ...newLayout,
    });
    this.keyboard.setInput(this.input);
  },
  methods: {
    onChange(e) {
      this.$emit('onChange', e);
    },
    onKeyPress(button) {
      this.$emit('onKeyPress', button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === '{shift}' || button === '{lock}') this.handleShift();

      if (button === '{enter}') this.$emit('close', true);
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === 'default' ? 'shift' : 'default';

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
    onClickOutside() {
      if (this.isOpen) {
        this.$emit('close', true);
      }

      this.isOpen = true;
    },
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.keyboard-wrapper {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 20px;
  width: 100%;
  background-color: #ececec;

  .simple-keyboard {
    padding: 0;
  }

  .input {
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 20px;
    outline: none;
    border: none;
    border: 1px solid #b5b5b5;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 32px;

    &::placeholder {
      opacity: 0.5;
    }

    &:focus::placeholder {
      opacity: 0;
    }
  }

  :deep(.hg-button) {
    height: 80px !important;
    font-size: 32px;
  }

  :deep(.hg-button-enter span),
  :deep(.hg-button-shift span),
  :deep(.hg-button-lock span),
  :deep(.hg-button-tab span),
  :deep(.hg-button-bksp span),
  :deep(.hg-button-reset span) {
    display: none !important;
  }

  :deep(.hg-button-enter:after) {
    content: 'Enter ↵';
  }

  :deep(.hg-button-reset) {
    max-width: 200px;
  }

  :deep(.hg-button-reset:after) {
    content: 'Reset';
  }

  &.DonatorsList {
    &:deep(.hg-button-enter) {
      background: #c89c66;
      color: #fff;
    }
    &:deep(.hg-button-enter:after) {
      content: 'Suchen ↵';
    }
  }
  :deep(.hg-button-shift:after) {
    content: 'Shift ⇧';
  }

  :deep(.hg-button-lock:after) {
    content: 'Caps Lock ⇪';
  }

  :deep(.hg-button-tab:after) {
    content: 'Tab ⇥';
  }

  :deep(.hg-button-bksp:after) {
    content: 'Backspace ⌫';
  }

  .hide-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    margin-top: -50px;
    z-index: -1;
    width: 100px;
    height: 100px;
    background-color: #ececec;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 30%;
      height: auto;
      transform: translateY(-10px);
    }
  }
}
</style>
